import "./App.css";
import React, { useState, useRef, useEffect } from "react";
import { FreeMode } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import Chatbox from "./components/Chatbox";
// 環境變數
const API_URL = process.env.REACT_APP_URL;
const chatRoomId = process.env.REACT_APP_CHATROOM_ID;
const initialToken = process.env.REACT_APP_TOKEN;
const API_KEY = process.env.REACT_APP_API_KEY;
const API_TIMESTAMP = process.env.REACT_APP_TIMESTAMP;
// 商品資訊
const products = [
  {
    name: "栗大福",
    image: "/images/栗大福.png",
    description:
      "以傳統杵搗工藝製成的大福餅皮，口感柔軟彈牙有嚼勁。內餡選用香甜栗子和細膩紅豆餡，層次豐富，口感細緻。",
    keywords: ["栗大福", "栗子大福"],
  },
  {
    name: "桔大福",
    image: "/images/桔大福.png",
    description:
      "季節限定的整顆砂糖橘裹上白豆沙，鑲入以傳統杵搗工藝製成的純米麻糬外皮，酸中帶甜、甜而不膩，有著大福的軟糯彈嫩，也能咀嚼到橘子的鮮美，應景鮮果融合日式點心，讓美味更深刻。",
    keywords: ["桔大福", "橘子大福", "極大福", "集大福", "吉大福"],
  },
  {
    name: "花果福酥禮盒",
    image: "/images/花果福酥禮盒.png",
    description:
      "枝葉扶疏花果美，寶島的味覺遊：以台農17號金鑽鳳梨為餡底，輔以三倍有機玫瑰、櫻花及抹茶搭配柑橘，外皮選用歐洲發酵奶油，帶來更易於消化且健康的享受。",
    keywords: ["花果福酥", "花果酥", "花果福酥禮盒"],
  },
  {
    name: "經典燒菓子禮盒",
    image: "/images/經典燒菓子禮盒.png",
    description: `牛皮-銅鑼燒外皮，內裡是Ｑ彈軟糯的麻糬，一口咬下、替平凡的時光增添愜意氣息。
銅鑼燒-鬆軟綿密的餅皮，夾著綿綿的紅豆沙餡，甜而不膩的交融在滿足的感動中。`,
    keywords: [
      "經典燒菓子禮盒",
      "燒菓子銅牛禮盒",
      "牛皮",
      "銅鑼燒",
      "經典燒果子禮盒",
    ],
  },
  {
    name: "彩之里禮盒",
    image: "/images/彩之里禮盒.png",
    description:
      "羊羹是將寒天，紅豆等食材做成類似羊肉羹的點心，由禪寺開始到武家社會間流傳開來，乃至於流傳至朝廷、並擴展至一般社會大眾。",
    keywords: ["彩之里禮盒", "彩之裡禮盒", "彩之理禮盒", "羊羹", "羊羹禮盒"],
  },
];

// 初始對話紀錄
const initialMessages = [
  {
    text: "您好，歡迎光臨明月堂！很高興為您服務。請問有什麼需要幫忙的嗎？",
    sender: "ai",
  },
];

function App() {
  const [TOKEN, setTOKEN] = useState(initialToken);
  // 聊天內容
  const [messages, setMessages] = useState(initialMessages);
  // 語音輸入中
  const [isRecording, setIsRecording] = useState(false);

  // 商品輪播
  const swiperRef = useRef(null);
  // 展示商品
  const [activeIndex, setActiveIndex] = useState(0); // 預設第一個顯示第一個產品

  const handleSlideChange = (index) => {
    // 移動到指定商品
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(index);
    }
    setActiveIndex(index);
  };

  // refresh token
  const refreshToken = async () => {
    try {
      const response = await fetch(`${API_URL}/private/auth/refresh`, {
        method: "POST",
        headers: {
          "x-api-key": `${API_KEY}`,
          Authorization: `Bearer ${TOKEN}`,
        },
      });
      if (!response.ok) {
        throw new Error("刷新 token 失敗");
      }
      const data = await response.json();
      setTOKEN(data.access_token);
    } catch (error) {
      console.error("更新token錯誤:", error);
    }
  };

  // ai答覆中
  const [isAnswering, setIsAnswering] = useState(false);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const mediaRecorderRef = useRef(null);
  const recordedChunksRef = useRef([]);
  const [permissionGranted, setPermissionGranted] = useState(false);
  const audioRef = useRef(null);
  const firstPlay = useRef(true);

  const setAudioInit = () => {
    firstPlay.current = false;
    if (audioRef.current) {
      // 假裝互動 因為音檔播放
      audioRef.current.src =
        "https://teamsyncblob.s3.ap-southeast-1.amazonaws.com/ChatRoom_Speech/2cac0736-24dc-42ea-a7c6-7aa533511b44/ad10e402-aeca-4f23-a375-44235cd77b1f.mp3";
      audioRef.current
        .play()
        .then(() => setIsAudioPlaying(true))
        .catch((e) => {
          console.error("Error playing audio:", e);
          setIsAudioPlaying(false);
        });
    }
    startRecording();
  };
  // 當組件加載時請求麥克風權限
  const requestMicrophonePermission = async () => {
    try {
      // 請求麥克風權限
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      setPermissionGranted(true);
      // 停止流的獲取以釋放資源
      stream.getTracks().forEach((track) => track.stop());
    } catch (err) {
      console.error("Microphone permission denied:", err);
      setPermissionGranted(false);
    }
  };
  useEffect(() => {
    requestMicrophonePermission();
  }, []);

  // 開始錄音
  const startRecording = async () => {
    if (isAnswering) return;
    if (!permissionGranted) requestMicrophonePermission();
    stopAudio();
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          recordedChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = handleRecordingStop;

      mediaRecorderRef.current.start();
      setIsRecording(true);
    } catch (err) {
      requestMicrophonePermission();
      console.error("Error starting recording:", err);
    }
  };

  // 停止錄音
  const stopRecording = () => {
    mediaRecorderRef.current.stop();
    setIsRecording(false);
  };

  // 處理音檔
  const handleRecordingStop = async () => {
    const blob = new Blob(recordedChunksRef.current, { type: "audio/webm" });
    recordedChunksRef.current = [];
    setIsRecording(false);
    getAiResponse(blob);
  };

  // 處理聊天
  const handleChatMessage = async (text, user) => {
    setMessages((prev) => [
      ...prev,
      {
        text,
        sender: user,
      },
    ]);
  };

  const abortControllerRef = useRef(null);

  // 處理ai回覆
  const getAiResponse = async (blob) => {
    setIsAnswering(true);

    if (!blob) {
      addMessage("抱歉，請您再說一次。", "ai");
      return;
    }
    abortRequest();

    abortControllerRef.current = new AbortController();
    const signal = abortControllerRef.current.signal;

    const formData = await createFormData(blob);

    try {
      const response = await fetchAiResponse(formData, signal);
      if (!response.ok) {
        const error = new Error();
        error.response = response;
        throw error;
      }
      const data = await response.json();

      handleResponseData(data);
    } catch (error) {
      await handleError(error);
    } finally {
      setIsAnswering(false);
    }
  };

  const createFormData = async (blob) => {
    const formData = new FormData();
    // 9/17前用的timestamp
    const currentTime = Math.floor(Date.now() / 1000);
    const targetTimestamp = parseInt(API_TIMESTAMP);
    formData.append("chat_type", "ai_query");
    formData.append("speech_keywords", "");
    formData.append("client_id", "");
    formData.append("web_search", false);
    formData.append("tts", true);
    formData.append("agent_type", "image_reader");
    formData.append("temperature", 0.7);
    formData.append("channel_type", "web");
    formData.append("blob_ids", "");
    formData.append(
      "asr_service",
      currentTime < targetTimestamp ? "replicate_deployment" : "replicate",
    );
    formData.append("file", blob, `${Date.now()}.webm`);
    return formData;
  };

  const fetchAiResponse = async (formData, signal) => {
    return fetch(`${API_URL}/private/chatrooms/chat/ai/${chatRoomId}/speech`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${TOKEN}`,
        "x-api-key": `${API_KEY}`,
      },
      signal,
    });
  };

  const handleResponseData = (data) => {
    if (!data) {
      throw new Error("無效的回應數據");
    }

    if (data.question) {
      handleChatMessage(data.question?.message, "user");
    }
    if (data.message) {
      handleChatMessage(data.message, "ai");
    }
    if (data.blobs && data.blobs.length > 0) {
      const audioBlob = data.blobs.find((blob) =>
        blob.content_type.startsWith("audio/"),
      );
      if (audioBlob) {
        playAudio(audioBlob.url);
      }
    }
  };

  const handleError = async (error) => {
    console.error("取得ai回覆錯誤:", error.response);
    if (error.response && error.response.status === 401) {
      try {
        await refreshToken();
      } catch (refreshError) {
        console.error("刷新 token 失敗:", refreshError);
      }
    }
    addMessage("不好意思，請您再說一次。", "ai");
  };

  const addMessage = (text, sender) => {
    setMessages((prev) => [
      ...prev,
      {
        text,
        sender,
      },
    ]);
  };

  // 播放語音
  const playAudio = (url) => {
    resetAudio();
    if (audioRef.current) {
      audioRef.current.src = url;
      audioRef.current
        .play()
        .then(() => setIsAudioPlaying(true))
        .catch((e) => {
          console.error("Error playing audio:", e);
          setIsAudioPlaying(false);
        });
      setIsAudioPlaying(true);
      audioRef.current.onended = () => stopAudio();
    }
  };

  // 停止播放語音
  const stopAudio = () => {
    resetAudio();
    setIsAudioPlaying(false);
  };

  // 重置音頻
  const resetAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  };
  // 結束對話並清除資料
  const resetChat = () => {
    setMessages(initialMessages);
    stopAudio();
    abortRequest();
  };

  // 中止請求
  const abortRequest = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = null;
    }
  };

  // 檢查最新的對話中是否有提到商品名稱,有就顯示該商品
  useEffect(() => {
    const lastMessage = messages[messages.length - 1];
    const keyword = products.find((product) =>
      product.keywords.some((kw) => lastMessage.text.includes(kw)),
    );
    if (keyword) {
      const index = products.indexOf(keyword);
      handleSlideChange(index);
    }
  }, [messages]);

  // 清理資源
  useEffect(() => {
    return () => {
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.stop();
      }
    };
  }, []);

  return (
    <div className="App fixed flex h-full w-screen flex-col overflow-y-auto overflow-x-hidden">
      {/* logo */}
      <div className="flex items-center justify-between px-24 pb-32 pt-40 sm:px-48">
        <div className="flex flex-col items-center gap-10">
          <img
            src="/images/qr-web.png"
            alt="official web qr-code"
            width={412}
            height={412}
            loading="lazy"
            className="h-[25.75rem] w-[25.75rem]"
          />
          <span className="text-6xl font-semibold text-[#555555]">
            官方網站
          </span>
        </div>
        <img
          src="/images/logo.png"
          alt="logo"
          width={616}
          height={300}
          loading="lazy"
          className="h-[18.8rem] w-[38.5rem]"
          onClick={resetChat}
        />
        <div className="flex flex-col items-center gap-10">
          <img
            src="/images/qr-fanpage.png"
            alt="fan page qr-code"
            width={412}
            height={412}
            loading="lazy"
            className="h-[25.75rem] w-[25.75rem]"
          />
          <span className="text-6xl font-semibold text-[#555555]">
            粉絲專頁
          </span>
        </div>
      </div>
      {/* ai chat */}
      <main
        className="grid flex-1 grid-cols-2 px-24 py-32"
        style={{
          backgroundImage: `url(${"/images/背景.png"})`,
          backgroundSize: "cover",
        }}
      >
        <Chatbox messages={messages} isAnswering={isAnswering} />
        <div className="relative flex h-full items-center justify-center">
          <img
            loading="lazy"
            width={1615}
            height={1615}
            src={isAudioPlaying ? "/images/ai.gif" : "/images/ai-default.png"}
            alt="ai"
            className="aspect-square h-full w-[101rem] max-w-none"
          />
          <audio ref={audioRef} className="none" />
          <button
            onClick={
              firstPlay.current
                ? setAudioInit
                : isRecording
                  ? stopRecording
                  : startRecording
            }
          >
            <img
              loading="lazy"
              width={435}
              height={425}
              src={
                isRecording
                  ? "/images/mic-clicked.svg"
                  : "/images/mic-default.svg"
              }
              alt="mic"
              className="absolute left-1/2 top-1/2 h-auto w-[27rem] translate-x-[-50%] translate-y-[45%]"
            />
          </button>

          {/* mic */}
        </div>
      </main>
      {/* product list*/}
      <div className="w-full py-24 pl-20">
        <Swiper
          slidesPerView={3.5}
          breakpoints={{
            0: {
              slidesPerView: 3.5,
              spaceBetween: 20, // 可根據需要調整間距
            },
            768: {
              spaceBetween: 20,
            },
            1024: {
              spaceBetween: 30,
            },
            1440: {
              spaceBetween: 60,
            },
          }}
          freeMode={true}
          className="w-full !pr-20"
          modules={[FreeMode]}
          ref={swiperRef}
        >
          {products.map((product, index) => (
            <SwiperSlide key={index} onClick={() => handleSlideChange(index)}>
              <div
                className={`overflow-hidden rounded-[2rem] border-4 border-[#949494] ${
                  activeIndex === index
                    ? "!border-main-red !bg-main-red shadow"
                    : "bg-[#949494]"
                }`}
              >
                <img
                  loading="lazy"
                  className="mx-auto block h-auto max-h-96 w-full max-w-[52rem] object-cover"
                  src={product.image}
                  alt={product.name}
                  width={640}
                  height={384}
                />
                <div
                  className={`flex items-center justify-center bg-white px-4 py-8 text-6xl text-[#555555] ${
                    activeIndex === index
                      ? "!bg-main-red font-semibold !text-white"
                      : ""
                  }`}
                >
                  {product.name}
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="h-8 min-h-8 w-full bg-[#DCD2C5]"></div>
      {/* product detail */}
      <div className="flex flex-1 flex-col items-center justify-center gap-[7.5rem] px-24 py-[8.5rem] sm:flex-row">
        <img
          width={1200}
          height={894}
          src={products[activeIndex].image}
          alt={products[activeIndex].name}
          className="h-[55.875rem] w-[75rem] flex-shrink-0 overflow-hidden rounded-[2rem] object-cover"
        />
        <div className="flex flex-col gap-[6.5rem]">
          <span className="text-8xl font-semibold text-main-red">
            {products[activeIndex].name}
          </span>
          <p className="whitespace-break-spaces text-[4rem] font-normal leading-[6rem]">
            {products[activeIndex].description}
          </p>
        </div>
      </div>
    </div>
  );
}

export default App;
