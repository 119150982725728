import React, { useEffect, useRef, useCallback } from "react";

const Chatbox = ({ messages, isAnswering }) => {
  const messagesEndRef = useRef(null);

  const scrollToBottom = useCallback(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages, scrollToBottom, isAnswering]);

  return (
    <div className="relative flex h-full items-center justify-center">
      <div className="absolute bottom-0 left-0 right-0 top-0 flex h-full w-full flex-col gap-7 overflow-y-auto rounded-2xl bg-black bg-opacity-20 p-8">
        {messages.map((message, index) => (
          <div
            key={index}
            className={`flex w-fit flex-col gap-4 ${
              message.sender === "user" ? "max-w-[70%] self-end" : "self-start"
            }`}
          >
            {message.sender === "ai" && (
              <div className="flex items-center gap-4 text-[4rem]">
                <img
                  src="/images/ai-avatar.svg"
                  alt="ai-avatar"
                  className="h-16 w-16"
                />
                <span className="font-semibold text-white">AI 老師傅</span>
              </div>
            )}
            <div
              className={`rounded-2xl p-8 text-7xl leading-[1.2] ${
                message.sender === "ai" ? "bg-[#FFFFFFCC]" : "bg-[#BAE3BA]"
              }`}
            >
              <span className="whitespace-break-spaces font-medium">
                {message.text}
              </span>
            </div>
          </div>
        ))}
        {isAnswering && (
          <div className={`flex w-fit flex-col gap-4 self-end`}>
            <div
              className={`w-fit rounded-2xl bg-[#BAE3BA] p-8 text-7xl leading-[1.2]`}
            >
              <div className="flex animate-pulse items-center gap-12">
                <div className="h-6 w-6 rounded-full bg-[#3E3D3D]"></div>
                <div className="h-6 w-6 rounded-full bg-[#3E3D3D]"></div>
                <div className="h-6 w-6 rounded-full bg-[#3E3D3D]"></div>
              </div>
            </div>
          </div>
        )}
        <div className="hide" ref={messagesEndRef} />
      </div>
    </div>
  );
};

export default Chatbox;
